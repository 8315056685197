import { useQuery } from '@vue/apollo-composable'

export function query (queryName: any, variables: any, fetchPolicy: any = null) {
  return new Promise((resolve, reject) => {
    const { onResult, onError } = useQuery(queryName, variables, fetchPolicy)

    onResult((queryResult: any) => {
      if (!queryResult.loading) {
        resolve(queryResult.data)
      }
    })

    onError((error: any) => {
      reject(error.graphQLErrors)
    })
  })
}