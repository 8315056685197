import { useMutation } from '@vue/apollo-composable'
import { apolloClient } from '~/composables/apolloClient/index'

export async function mutation (queryName: any, variables: any = null, refreshApolloClient: boolean = false) {
  if (refreshApolloClient) {
    apolloClient()
  }
  const { mutate } = useMutation(queryName, {
    variables
  })

  let result
  let error
  try {
    result = await mutate()
  } catch (err) {
    error = err
  }
  return { result, error }
}
