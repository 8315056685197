import { ElLoading } from 'element-plus'
import { ElMessageBox } from 'element-plus'

const components = {
  ElLoading,
  ElMessageBox
}

export default defineNuxtPlugin((nuxtApp) => {
  Object.entries(components).forEach(([name, component]) => {
    nuxtApp.vueApp.component(name, component)
  })
})
