import { ApolloClient, InMemoryCache, ApolloLink, fromPromise } from '@apollo/client/core'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from '@apollo/client/link/error'
import REFRESH_TOKEN from '@/graphql/auth/mutation/refreshToken.gql'

let isRefreshing = false
let pendingRequests: Function[] = []
let apolloClient: ApolloClient<any> | null = null

const resolvePendingRequests = () => {
    pendingRequests.forEach(callback => callback())
    pendingRequests = []
}

export function createApolloClient() {
    const config = useRuntimeConfig()

    const httpLink = createUploadLink({
        uri: config.public.httpEndpoint,
        headers: $cookies.get('apollo-token')
            ? {
                Authorization: `Bearer ${$cookies.get('apollo-token')}`
            }
            : {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Authorization',
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                'Access-Control-Expose-Headers': '*'
            }
    })

    const errorLink = onError(({ graphQLErrors, operation, forward }) => {
        const { $showError } = useNuxtApp()
        if (graphQLErrors) {
            for (const err of graphQLErrors) {
                if (err.message.includes('Unauthenticated') ||
                    err.message.includes('The token has expired') ||
                    err?.reason?.includes('authentication_error')
                ) {
                    if (isRefreshing) {
                        return fromPromise(
                            new Promise(resolve => {
                                pendingRequests.push(() => {
                                    const token = $cookies.get('apollo-token')
                                    if (token) {
                                        operation.setContext(({ headers = {} }) => ({
                                            headers: {
                                                ...headers,
                                                Authorization: `Bearer ${token}`
                                            }
                                        }))
                                    }
                                    resolve(null)
                                })
                            })
                        ).flatMap(() => forward(operation))
                    }

                    isRefreshing = true

                    return fromPromise(
                        refreshToken()
                            .catch(error => {
                                console.error("Error refreshing token", error)
                                pendingRequests = []
                                isRefreshing = false
                                $cookies.remove('apollo-token')
                                $cookies.remove('apollo-token-refresh')
                                window.location.href = '/login'
                                return null
                            })
                            .then(tokens => {
                                if (tokens) {
                                    const { access_token, refresh_token } = tokens
                                    $cookies.remove('apollo-token')
                                    $cookies.remove('apollo-token-refresh')
                                    $cookies.set('apollo-token', access_token, `${365 * 20}d`)
                                    $cookies.set('apollo-token-refresh', refresh_token, `${365 * 20}d`)

                                    if (apolloClient) {
                                        const newHttpLink = createUploadLink({
                                            uri: config.public.httpEndpoint,
                                            headers: {
                                                Authorization: `Bearer ${access_token}`
                                            }
                                        })
                                        apolloClient.setLink(ApolloLink.from([errorLink, newHttpLink]))
                                    }

                                    operation.setContext(({ headers = {} }) => ({
                                        headers: {
                                            ...headers,
                                            Authorization: `Bearer ${access_token}`
                                        }
                                    }))
                                }
                                isRefreshing = false
                                resolvePendingRequests()
                                return forward(operation)
                            })
                    ).flatMap(() => forward(operation))
                } else if (err?.reason?.includes('unhandled_error')) {
                    $showError(err)
                }
            }
        }
    })

    const refreshToken = async () => {
        const refresh_token = $cookies.get('apollo-token-refresh')
        if (!refresh_token) throw new Error('No refresh token available')

        const refreshLink = createUploadLink({
            uri: config.public.httpEndpoint
        })

        const client = new ApolloClient({
            link: refreshLink,
            cache: new InMemoryCache()
        })

        try {
            const { data } = await client.mutate({
                mutation: REFRESH_TOKEN,
                variables: {
                    refresh_token,
                    system_language: navigator.language.split('-')[0].toUpperCase()
                }
            })
            return data.refreshToken
        } catch (error) {
            throw error
        }
    }

    apolloClient = new ApolloClient({
        link: ApolloLink.from([errorLink, httpLink]),
        cache: new InMemoryCache(),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'network-only',
                errorPolicy: 'ignore'
            },
            query: {
                fetchPolicy: 'network-only',
                errorPolicy: 'all'
            }
        }
    })

    return apolloClient
}