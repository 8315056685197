import { computed, onMounted, onUnmounted, ref } from "vue"

export const useBreakpoints = () => {
  let containerWidth = ref(window.innerWidth)
  let containerHeight = ref(window.innerHeight)

  onMounted(() => window.addEventListener('resize', resizeContainer))
  onUnmounted(() => window.removeEventListener('resize', resizeContainer))

  const resizeContainer = () => {
    containerWidth.value = window.innerWidth
    containerHeight.value = window.innerHeight
  }

  const width = computed(() => containerWidth.value)
  const height = computed(() => containerHeight.value)

  return { width, height }
}

export function onTableResize(minusValue: number) {
  return window.screen.height - minusValue
}