import { ref } from 'vue'

function createDebounce () {
  let timeout: any = null
  return function (fnc: () => void) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fnc()
    }, 1000)
  }
}

function viewItem (route: string, row: any) {
  navigateTo(`${route}/${row.uuid}`)
}

function newWindowItem (route: string, row: any) {
  window.open(`${route}/${row.uuid}`)
}

function newEntity (route: string) {
  navigateTo(`${route}/new`)
}

function downloadStringFile (object: any, fileName: string) {
  const url = URL.createObjectURL(
    new Blob(
      // eslint-disable-next-line prefer-regex-literals
      [object.replace(new RegExp(',', 'g'), ';')],
      {
        type: 'application/octet-stream'
      }
    )
  )
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
}

export function getTableInitialVariables (sortBy: string, sortDesc: boolean, queryName: string) {
  const containerWidth = ref(document.body.clientWidth)

  window.addEventListener('resize', () => { containerWidth.value = document.body.clientWidth })

  return {
    loading: ref(false),
    first: 10,
    sortBy,
    sortDesc,
    defaultSort: ref({ prop: sortBy, order: sortDesc ? 'descending' : 'ascending' }),
    search: ref(''),
    showChild: ref(false),
    numberOfFiltersActive: ref(0),
    debounce: createDebounce(),
    viewItem,
    newWindowItem,
    newEntity,
    downloadStringFile,
    page: 1,
    filterForm: ref(false),
    filterExists: ref(false),
    itemsPerPageArray: [10, 25, 50, 200],
    containerWidth,
    paginationLayout: 'total, sizes, prev, pager, next',
    keyHovered: ref(null),
    defaultResponse: {
      [queryName]: {
        data: [],
        paginatorInfo: {
          total: 0,
          currentPage: 1,
          lastPage: 1
        }
      }
    }
  }
}
