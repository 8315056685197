import { ElNotification } from 'element-plus'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      showSuccess: (message) => {
        ElNotification({
          showClose: true,
          message,
          type: 'success',
          customClass: 'success-notification',
          position: 'bottom-right',
          zIndex: 3000
        })
      }
    }
  }
})
