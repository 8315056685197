import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import es from 'element-plus/es/locale/lang/es'
import en from 'element-plus/es/locale/lang/en'

export default defineNuxtPlugin((nuxtApp) => {
  let language = en
  switch (navigator.language) {
    case 'es':
      language = es
      break
    case 'en':
      language = en
      break
    default:
      language = en
      break
  }
  nuxtApp.vueApp.use(ElementPlus, {
    locale: language
  })

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    nuxtApp.vueApp.component(key, component)
  }
})
