import SEARCH from '~/graphql/search/query/search.gql'

export function apolloSearch(
    params: any,
    filterExists = false,
    filterSaved = null,
    fetchPolicy = null
) {
    return new Promise(async (resolve, reject) => {
        let apolloQuery
        let variables = {}
        try {
            variables = {
                query: params.search,
                page: params.page ?? 1,
                first: params.first ?? 10
            }
            apolloQuery = SEARCH

            resolve(await query(apolloQuery, variables, { fetchPolicy }))
        } catch (err) {
            reject(err)
        }
    })
}
