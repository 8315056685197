import { initializeApp, getApps } from 'firebase/app'

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()

    if (!getApps().length) {
        const firebaseConfig = {
            apiKey: config.public.firebaseApiKey,
            authDomain: config.public.firebaseAuthDomain,
            databaseURL: config.public.firebaseDatabaseURL,
            projectId: config.public.firebaseProjectId,
            storageBucket: config.public.firebaseStorageBucket,
            messagingSenderId: config.public.firebaseMessagingSenderId
        }

        const app = initializeApp(firebaseConfig)

        nuxtApp.vueApp.provide('firebaseApp', app)
        nuxtApp.provide('firebaseApp', app)
    }

})