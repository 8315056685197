import validate from "/__w/landing-page/landing-page/node_modules/nuxt/dist/pages/runtime/validate.js";
import set_45auth_45global from "/__w/landing-page/landing-page/middleware/set-auth.global.ts";
import manifest_45route_45rule from "/__w/landing-page/landing-page/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  set_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/__w/landing-page/landing-page/middleware/auth.ts"),
  guest: () => import("/__w/landing-page/landing-page/middleware/guest.ts")
}