<template>
  <i v-html="svg" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true
  }
})
const modules = import.meta.glob(['~/assets/icons/*/*.svg', '~/assets/icons/*.svg'], {
  query: 'raw',
  import: 'default',
  eager: true
})

const svg = computed(() => {
  return modules['/assets/icons/' + props.name + '.svg'] ?? ''
})
</script>