import vueAppleLogin from 'vue-apple-login';
import moment from 'moment';

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();

    nuxtApp.vueApp.use(vueAppleLogin, {
        clientId: config.public.appleClientId,
        scope: 'name email',
        redirectURI: config.public.appleRedirectUrl,
        state: moment().format('YYYY-MM-DD HH:mm:ss'),
        usePopup: true
    });
})