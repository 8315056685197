import { ElNotification } from 'element-plus'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      showError: (error, t = null) => {
        if (error?.length === 0) {
          //
        } else if (error?.message && getErrorArray().some(i => error.message.includes(i))) {
          $cookies.remove('apollo-token')
          $cookies.remove('apollo-token-refresh')
          window.location.href = '/login'
        } else if (error?.message && error.message.includes('delete from')) {
          ElNotification({
            showClose: true,
            message: t('cannot_delete_entity_because_is_in_use'),
            type: 'error',
            customClass: 'error-notification',
            position: 'bottom-right',
            zIndex: 3000
          })
        } else if (error?.message && error.message.includes('SQLSTATE')) {
          ElNotification({
            showClose: true,
            message: t('an_error_has_ocurred_our_technicians_are_already_working_on_it_please_try_again_later'),
            type: 'error',
            customClass: 'error-notification',
            position: 'bottom-right',
            zIndex: 3000
          })
        } else if (error?.message && error?.message !== 'Error') {
          ElNotification({
            showClose: true,
            message: error?.message ?? error,
            type: 'error',
            customClass: 'error-notification',
            position: 'bottom-right',
            zIndex: 3000
          })
        } else if (error?.length && error[0].message && getErrorArray().some(i => error[0].message.includes(i))) {
          $cookies.remove('apollo-token')
          $cookies.remove('apollo-token-refresh')
          window.location.href = '/login'
        } else if (error?.name === 'ApolloError' || JSON.stringify(error).includes('ApolloError')) {
          ElNotification({
            showClose: true,
            message: t('an_error_has_ocurred_our_technicians_are_already_working_on_it_please_try_again_later'),
            type: 'error',
            customClass: 'error-notification',
            position: 'bottom-right',
            zIndex: 3000
          })
        } else {
          ElNotification({
            showClose: true,
            message: error?.message ?? error,
            type: 'error',
            customClass: 'error-notification',
            position: 'bottom-right',
            zIndex: 3000
          })
        }
      }
    }
  }
})

function getErrorArray () {
  return ['Unauthenticated',
    'Su usuario no está autenticado',
    'El usuario no está autenticado',
    'The user is not authenticated',
    'Your user is not logged in',
    'Error en la petición, su usuario está inactivo',
    'Votre utilisateur n\'est pas connecté',
    'Las credenciales proporcionadas',
    '您的用户没有登录']
}

function notAuthorizedMessage () {
  switch (navigator.language) {
    case 'es-ES':
      return 'No estás autorizado a entrar'
    case 'en-EN':
      return 'You are not authorized to enter'
    case 'fr-FR':
      return 'Vous n\'estez pas autorisé d\'entrer'
    case 'de-DE':
      return 'Du bist nicht berechtigt, aufzurufen'
    case 'sv-SE':
      return 'Du är inte godkänt att in'
    case 'ar-AE':
      return 'ليس لديك الصلاحية للدخول'
    default:
      return 'You are not authorized to enter'
  }
}
