<template>
  <ElConfigProvider :locale="elementPlusLanguage">
    <div class="div-container" :class="`${isUserLogged ? 'logged' : ''}`">
      <UtilsHeader />
      <div class="not-found-wrapper">
        <div class="cta-container">
          <div class="card-one" :class="`${isUserLogged ? 'logged' : ''}`">
            <div class="card-content">
              <div class="card-container">
                <div class="card-title">
                  <span :class="width <= 1100 ? 'heading1' : 'heading2'">
                    {{ $t('cant_find_page') }}</span
                  >
                </div>
                <div class="card-subtitle">
                  <span class="body1">{{ $t('sorry_page_not_exists') }}</span>
                  <br />
                  <span class="body1">
                    {{ $t('try_searching_our_site') }}
                  </span>
                </div>
                <div class="card-action">
                  <ElButton
                    type="primary"
                    class="dark"
                    @click="router.push('/')"
                  >
                    {{ $t('take_me_home') }}
                  </ElButton>
                </div>
              </div>
            </div>
          </div>
          <div class="card-two" :class="`${isUserLogged ? 'logged' : ''}`" />
        </div>
      </div>
      <UtilsFooter />
    </div>
  </ElConfigProvider>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useFavicon } from '@vueuse/core'

definePageMeta({
  name: '404Page'
})

const { width } = useBreakpoints()
const router = useRouter()
const { $eventBus } = useNuxtApp()
const { locale } = useI18n({ useScope: 'global' })
const newColorScheme = ref('light')
const elementPlusLanguage = ref('en')

const favicon = computed(() => {
  return newColorScheme.value === 'dark' ? '/favicon.svg' : '/favicon-dark.svg'
})

watch(newColorScheme, () => {
  useFavicon(favicon.value, {
    rel: 'icon'
  })
})

const isUserLogged = computed(() => {
  return getAuthUser()?.value?.uuid ? true : false
})

onMounted(() => {
  setColorScheme()
  if (isUserLogged.value) {
    locale.value = getAuthUser()?.value?.language === 'es' ? 'es' : 'en'
    localStorage.setItem('languageSelected', locale.value)
  }
  if (localStorage?.getItem('languageSelected')) {
    elementPlusLanguage.value = localStorage?.getItem('languageSelected')
    locale.value = localStorage?.getItem('languageSelected')
  } else {
    elementPlusLanguage.value =
      navigator.language.split('-')[0] === 'es' ? 'es' : 'en'
    locale.value = navigator.language.split('-')[0] === 'es' ? 'es' : 'en'
    localStorage.setItem('languageSelected', locale.value)
  }
  $eventBus.$on('setLocale', ($event) => {
    elementPlusLanguage.value = $event
    locale.value = $event
    localStorage.setItem('languageSelected', locale.value)
  })
})

const setColorScheme = () => {
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    newColorScheme.value = 'dark'
  }
  window
    .matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', (event) => {
      newColorScheme.value = event.matches ? 'dark' : 'light'
    })
}
</script>

<style scoped lang="scss">
.div-container {
  margin: 0;
  margin-top: 169px;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: calc(
    100vh - 139px
  ); /* Establece el mínimo del alto de la vista */
  &.logged {
    margin-top: 98px;
  }
}

.not-found-wrapper {
  width: 100vw;
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: var(--brand-off-white);
  .cta-container {
    background-color: var(--brand-off-white);
    @media (min-width: 1100px) {
      height: 740px;
      overflow: hidden;
    }
    .card-one {
      @media (min-width: 1100px) {
        position: absolute;
        display: inline-block;
        width: 50vw;
        height: 770px;
        top: 139px;
        &.logged {
          top: 68px;
        }
      }
      @media (max-width: 1100px) {
        width: 100vw;
        position: relative;
        padding: 24px 0 56px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
    .card-two {
      background: url(/images/error.jpeg) rgba(243, 246, 248, 0.7) bottom center;
      background-size: cover !important;
      background-repeat: no-repeat;
      z-index: 1;
      @media (min-width: 1100px) {
        position: absolute;
        display: inline-block;
        height: 770px;
        width: 50vw;
        top: 139px;
        &.logged {
          top: 68px;
        }
        left: 50vw;
      }
      @media (max-width: 1100px) {
        width: 100vw;
        height: 375px;
      }
    }
    /** TARJETAS **/
    .card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .card-container {
        @media (min-width: 1100px) {
          width: 544px;
        }
        .card-title {
          @media (min-width: 1100px) {
            width: 478px;
          }
          .heading2 {
            color: var(--brand-off-black);
          }
        }
        .card-subtitle {
          @media (min-width: 1100px) {
            width: 313px;
          }
          margin-top: 16px;
          .body1-strong {
            color: var(--brand-off-black);
          }
        }
        .card-action {
          @media (min-width: 1100px) {
            width: 478px;
          }
          margin-top: 32px;
        }
      }
    }

    @media (min-width: 1100px) {
      .card-content {
        height: 770px;
      }
    }
  }
}
</style>