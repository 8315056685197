import { createI18n } from 'vue-i18n'

import es from '@/lang/es-ES.json'
import en from '@/lang/en-EN.json'
import fr from '@/lang/fr-FR.json'
import de from '@/lang/de-DE.json'
import sv from '@/lang/sv-SE.json'
import ar from '@/lang/ar-AE.json'

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: navigator.language.includes('es') ? 'es' :
    navigator.language.includes('en') ? 'en' :
      navigator.language.includes('fr') ? 'fr' :
        navigator.language.includes('de') ? 'de' :
          navigator.language.includes('sv') ? 'sv' :
            navigator.language.includes('ar') ? 'ar' :
              'en',
  messages: {
    es,
    en,
    fr,
    de,
    sv,
    ar
  }
})

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(i18n)
})
